.ftue-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--darker-purple);
  display: flex;
  align-items: center;
  z-index: 100;
}

.ftue-icon {
  object-fit: contain;
  height: 270px;
}

.FTUE-wrapper {
  justify-content: space-around;
}

.back-and-next {
  justify-content: space-evenly;
}

.ftue-btn {
  background-color: var(--orange);
  padding: 0.5rem 2rem;
  border: 1px solid var(--orange);
}

.ftue-btn.invert {
  background-color: transparent;
  color: var(--orange);
  border: 1px solid var(--orange);
}

.skip {
  padding: 0;
  color: var(--orange);
  background-color: transparent;
}

.page {
  display: inline-block;
}

.blurb {
  letter-spacing: 1px;
  min-height: 80px;
}

.progress-container {
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
  width: 30%;
}
