.sync-container {
  position: fixed;
  right: 30px;
  bottom: 60px;
}

.sync-container > button {
  background-color: var(--sync-button-enabled);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  transition: background-color 0.2s ease-in-out;
}

.sync-container > button:hover {
  background-color: var(--sync-button-disabled);
}
.sync-container img {
  max-width: 100%;
}
