@import './dramcorp.css';
@import './monolith.css';

.modal {
  position: relative;
  margin: 1rem;
  background: white;
  overflow: hidden;
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: auto 1fr;
  width: 80%;
  max-width: 600px;
  height: 80%;
  max-height: 400px;
}

@media (max-width: 768px) {
  .modal {
    max-width: 100%;
    width: 96%;
  }
  .modal .cta-container {
    flex-direction: column;
  }

  .modal .cta-container .cta + span {
    margin: 1rem 0;
  }
}

.modal .titlebar {
  display: flex;
  justify-content: space-between;
  background: black;
  color: white;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
}

.modal .title {
  color: white;
  padding: 0.35rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.modal .close-btn {
  width: 1rem;
  height: 1rem;
  position: absolute;
  right: 8px;
}

.modal .close-btn img {
  width: 100%;
}

.modal .close {
  border: none;
  margin: 0.3rem 0.25rem;
  background: white;
  border-radius: 0.1rem;
  cursor: pointer;
  font-weight: 600;
}

.modal .icon-and-title {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  position: relative;
}

.modal .window-icon {
  width: 32px;
  margin: 0 24px 0 0;
}

.modal .content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  max-width: 80%;
  margin: 0 auto;
  flex-direction: column;
}

.modal .content-wrapper {
  text-align: center;
}

/* achievement completion modal */
.cta-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 2rem;
}

.cta {
  padding: 0.45rem 1rem 0.5rem;
  text-decoration: none;
  line-height: 1;
}

.cta-container .cta + span {
  margin: 0 1rem;
}

/* Login Modal */
.login-modal .content-wrapper {
  text-align: left;
}

.modal.login-modal .content {
  justify-content: flex-start;
  max-width: 90%;
}

.login-modal .grid-columns {
  grid-template-columns: 60% 40%;
  height: 100%;
  position: relative;
}

.login-modal .left-pane {
  grid-template-rows: 30% 20% 50%;
  padding-right: 1rem;
}

.login-modal .right-pane {
  grid-template-rows: 50% 50%;
  position: relative;
}

.modal.login-modal .content-title {
  font-size: 34px;
  margin-bottom: 10px;
  padding: 0;
  align-self: flex-end;
}

.login-modal img {
  max-width: 200px;
}

.login-modal .input-container {
  align-self: flex-end;
}

.login-modal label {
  font-size: 12px;
  margin-bottom: 5px;
  display: block;
}

.login-modal input {
  background-color: transparent;
  margin-bottom: 13px;
  border: 1px solid var(--purple);
  color: white;
  padding: 8px 0;
  text-indent: 12px;
  font-size: 14px;
  line-height: 1.42857143;
  width: 100%;
}

.login-modal input::placeholder {
  color: var(--purple);
}

.login-modal .login-error {
  margin-bottom: 13px;
  font-size: 12px;
  color: var(--orange);
}

.login-modal .btn.login {
  border: 1px solid var(--orange);
  background: var(--orange);
  color: white;
  padding: 8px 12px;
}

.login-modal .btn.login:hover,
.login-modal .btn.login:active {
  background: var(--dark-orange);
}

.login-modal .close-btn {
  display: none;
}

@media (max-width: 768px) {
  .login-modal img {
    display: none;
  }
  .login-modal .grid-columns {
    grid-template-columns: none;
    grid-template-rows: auto auto;
  }
  .login-modal .left-pane {
    align-self: flex-end;
  }
}
