.slide-out {
  background-color: var(--dark);
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  bottom: 0;
  color: white;
  height: 210px;
  left: 0;
  overflow: hidden;
  padding: 2rem 2rem 0;
  position: fixed;
  right: 0;
  top: auto;
  transition: bottom 0.5s;
  z-index: 1000;
}

.closed {
  bottom: -260px;
}

.content-container {
  margin: 0 auto;
  max-width: 400px;
  text-align: center;
}

.content-container .flex {
  flex-direction: column;
}

@media (min-width: 480px) {
  .content-container .flex {
    flex-direction: row;
  }
}

.content-container button,
.content-container a {
  color: white;
  font-size: 1.5rem;
}

button.sync-option {
  transition: all 0.2s ease-in-out;
  border-bottom: 5px solid var(--dark);
  height: 100%;
  padding-bottom: 5px;
}

button.sync-option:hover {
  border-bottom: 5px solid var(--orange);
  padding-bottom: 5px;
}
