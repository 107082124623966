/*** Achievements ***/
.achievement-select-container {
  border-top: 1px solid var(--purple);
  border-bottom: 1px solid var(--purple);
}

.achievement-select-container:after {
  content: '\0025BC';
  color: white;
  font-size: 0.8rem;
  margin-left: 0.5rem;
  padding-top: 2px;
  pointer-events: none;
}

.achievements-filter {
  background-color: var(--darker-purple);
  border: none;
  -webkit-appearance: none;
}

.achievement-icon {
  width: 140px;
  height: 140px;
  object-fit: contain;
}

.achievements-filter-control-container {
  justify-content: space-evenly;
  display: none;
}

.achievements-filter-button {
}

.achievement-detail.overlay {
  justify-content: initial;
}

.achievement-detail-description {
  color: #e8e8e8;
}

.achievement-detail-close {
  position: absolute;
  top: 20px;
  left: 20px;
}

.achievement-detail-content {
  width: 97vw;
  height: 60vw;
  z-index: 20;
}

.achievement-detail-control {
  flex: 0 0 30px;
  padding-top: 3rem;
  display: none;
}

.arrow-icon {
  cursor: pointer;
}

@media (min-width: 992px) {
  .achievements {
    align-items: unset;
  }

  .achievement-select-container {
    display: none;
  }

  .achievements-filter-control-container {
    display: flex;
  }

  .achievements-list {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-left: 3.6rem;

    /* padding-right: 3.6rem;  */
  }

  .achievement-item {
    cursor: pointer;
    flex: 0 0 200px;
    margin-right: 2rem;
  }

  .achievement-name {
    max-width: 90%;
    padding-top: 1rem;
    font-size: 24px;
  }
  .achievement-detail-control {
    display: block;
  }

  .achievement-detail-content {
    margin-top: 95px;
  }

  .achievement-info {
    flex-direction: row;
    align-items: flex-start;
    margin-top: 170px;
    justify-content: space-between;
  }

  .achievement-icon {
    margin-top: 0;
  }

  .info-inner {
    flex-direction: row;
    padding-left: 4rem;
    padding-right: 4rem;
  }
}
