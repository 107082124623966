.avatar-container img {
  border-radius: 50%;
  height: 100%;
  height: 50px;
  overflow: hidden;
  position: relative;
  transition: filter 0.2s ease-out;
  width: 50px;
}

.avatar-container > img:hover {
  filter: brightness(1.25);
}

.logout-menu {
  position: absolute;
  top: 80px;
  transform: scale(0);
  transform-origin: top right;
  transition: all 0.2s ease-in-out;
  width: max-content;
}

.logout-menu.open {
  background-color: var(--light-gray);
  border-radius: 3px;
  transform: scale(1);
  z-index: 20;
}

.logout-menu .menu-item {
  color: white;
  display: block;
  font-size: 1.125rem;
  font-weight: 400;
  height: 100%;
  line-height: 2.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: left;
  transition: background-color 0.2s ease-in-out;
  width: 100%;
}

.logout-menu button.menu-item:hover {
  background-color: #ffffff14;
}
