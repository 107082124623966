/*** Menu ***/
.menu {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--dark);
}

.menu-header {
  height: 75px;
}

.menu-logo {
  height: 40px;
  object-fit: contain;
}

.menu-close {
  position: absolute;
  top: 1.1rem;
  left: 1.5rem;
}

button.menu-btn {
  line-height: 2.22;
  letter-spacing: 0.76px;
  padding-bottom: 0.5rem;
  margin-bottom: 2rem;
}
