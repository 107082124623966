.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.overlay::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--darker-purple);
  opacity: 0.95;
}

.overlay .overlay-inner {
  width: 80vw;
  height: 80vh;
  align-self: center;
  /* background-color: black; */
  position: absolute;
  z-index: 11;
}

.overlay[data-item-type='video'] .overlay-inner {
  width: 80vw;
  height: 45vw;
}

.overlay-close {
  position: fixed;
  top: 30px;
  left: 30px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.overlay iframe {
  border: none;
}

.loading-icon {
  height: 200px;
  object-fit: contain;
  animation: rotation 4s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
