.modal.monolith {
  background-color: var(--monolith-dark-gray);
}

.modal.monolith a {
  color: white;
}

.modal.monolith .content-wrapper {
  color: white;
  border: 1px solid var(--monolith-gray);
  border-top: 0 solid transparent;
}

.modal.monolith .titlebar {
  background-image: linear-gradient(
    to left,
    var(--monolith-dark-dirty-green),
    var(--monolith-dirty-green),
    var(--monolith-yellow)
  );
}

.modal.monolith .cta {
  border: 1px solid var(--monolith-yellow);
  background: var(--monolith-yellow);
}

.modal.monolith .cta:hover,
.modal.monolith .cta:active {
  background: var(--monolith-dirty-green);
}
