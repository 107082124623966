@import './components/modals/modals.css';

.bold {
  font-weight: bold;
}

.text-center {
  text-align: center;
}

.color-white {
  color: white;
}

.color-grey {
  color: grey;
}

.color-purple {
  color: var(--purple);
}

.font-lg {
  font-size: 18px;
}

.font-xl {
  font-size: 24px;
}

.mw-50 {
  max-width: 50%;
}

.mw-60 {
  max-width: 60%;
}

.mw-70 {
  max-width: 70%;
}

.mw-80 {
  max-width: 80%;
}

.mw-90 {
  max-width: 90%;
}

.w-50 {
  width: 50%;
}

.w-70 {
  width: 70%;
}

.w-80 {
  width: 80%;
}

.w-90 {
  width: 90%;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.p-b-3 {
  padding-bottom: 3rem;
}

.p-t-1 {
  padding-top: 1rem;
}

.p-t-2 {
  padding-top: 2rem;
}

.p-t-3 {
  padding-top: 3rem;
}

.p-l-3 {
  padding-left: 3rem;
}

.p-y-1 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.p-y-2 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.p-y-3 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.p-x-1 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.p-x-2 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.m-1 {
  margin: 1rem;
}

.m-2 {
  margin: 2rem;
}

.m-3 {
  margin: 3rem;
}

.m-4 {
  margin: 4rem;
}

.m-t-1 {
  margin-top: 1rem;
}

.m-t-2 {
  margin-top: 2rem;
}

.m-t-3 {
  margin-top: 3rem;
}

.m-t-4 {
  margin-top: 4rem;
}

.m-b-1 {
  margin-bottom: 1rem;
}

.m-b-2 {
  margin-bottom: 2rem;
}

.m-b-3 {
  margin-bottom: 3rem;
}

.m-b-4 {
  margin-bottom: 4rem;
}

.m-x-1 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.m-x-2 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.m-x-3 {
  margin-left: 3rem;
  margin-right: 3rem;
}

.m-x-4 {
  margin-left: 4rem;
  margin-right: 4rem;
}

.m-x-auto {
  margin-left: auto;
  margin-right: auto;
}

.d-none {
  display: none;
}

.hidden {
  visibility: hidden;
  pointer-events: none;
}

.flex {
  display: flex;
}

.flex-grow-1 {
  flex-grow: 1;
}

.wrap {
  flex-wrap: wrap;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-around {
  justify-content: space-around;
}

.justify-between {
  justify-content: space-between;
}

.justify-end {
  justify-content: end;
}

.align-self-center {
  align-self: center;
}

.column {
  flex-direction: column;
}

.row {
  flex-direction: row;
}

.grid {
  display: grid;
}

.pointer {
  cursor: pointer;
}

.close-btn {
  border: none;
  z-index: 30;
}

.close-icon {
  filter: invert();
}

.decoration-none {
  text-decoration: none;
}

.fade-in {
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (min-width: 992px) {
  .row-md {
    flex-direction: row;
  }
}

/* @media (min-width: 768px) { ... } */

/* @media (min-width: 992px) { ... } */

/* @media (min-width: 1200px) { ... } */
