/*** Bio ***/
.bio-id {
  line-height: 2.22;
  letter-spacing: 1.53px;
}

/*** Avatar ***/
.avatar {
  display: flex;
  justify-content: center;
}

.avatar-image {
  height: 267px;
  object-fit: contain;
}

/* @media (min-width: 576px) { ... }


@media (min-width: 768px) { ... } */

@media (min-width: 992px) {
  .bio {
    display: flex;
    justify-content: space-between;
    padding-top: 0;
    padding-bottom: 0;
  }

  .bio-info {
    flex: 0 0 40%;
    padding-top: 5rem;
    padding-left: 80px;
  }

  .bio-greeting {
    font-size: 43px;
    text-align: left;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .bio-id {
    font-size: 36px;
    line-height: 1.11;
    letter-spacing: 1.53px;
    text-align: left;
  }

  .bio-type {
    font-size: 64px;
    max-width: 100%;
    text-align: left;
  }

  .avatar {
    flex: 0 0 50%;
  }

  .avatar-image {
    height: 630px;
  }
}

/* @media (min-width: 1200px) { ... } */
