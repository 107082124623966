/*** Dram Drive ***/
.inventory-header {
  padding-top: 1.8rem;

  text-align: center;
}

.item-list {
  justify-content: space-between;
  padding-top: 2rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.drive-item {
  flex: 0 0 45%;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.item-icon {
  height: 80px;
  object-fit: contain;
  padding-bottom: 0.8rem;
}

.item-title {
  letter-spacing: 0.5px;
  max-width: 70%;
}

.dramdrive-icon {
  object-fit: contain;
  height: 10rem;
}

@media (min-width: 992px) {
  .inventory-header {
    text-align: left;
    padding-left: 2rem;
  }

  .item-list {
    margin-top: 4.5rem;
    padding-left: 0;
    padding-right: 0;
  }

  .drive-item {
    flex: 0 0 20%;
  }
}
