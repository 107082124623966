@import './styles/base.css';

@import './styles/components/achievements.css';
@import './styles/components/bio.css';
@import './styles/components/drive.css';
@import './styles/components/ftue.css';
@import './styles/components/menu.css';
@import './styles/components/overlay.css';
@import './styles/components/sync-button.css';
@import './styles/components/sync-slideout.css';
@import './styles/components/logout-container.css';

:root {
  --dark-orange: #c2392e;
  --orange: #df4e38;
  --light-orange: #f9a963;
  --dark: #17172b;
  --dark-purple: #00002d;
  --purple: #716fb3;
  --bright-purple: #7b68e7;
  --mid-purple: #191945;
  --darker-purple: #0d0d20;
  --light-purple: #d6c5e0;
  --gradient: linear-gradient(to right, #df4e38 4%, #7b68e7 90%);
  --light-gray: #312b44;

  --monolith-gray: #535353;
  --monolith-dark-gray: #292929;
  --monolith-yellow: #877d05;
  --monolith-dirty-green: #546013;
  --monolith-dark-dirty-green: #405001;

  --sync-button-enabled: #7989ff;
  --sync-button-disabled: #d5d5d5;
}

@font-face {
  font-display: auto;
  font-family: 'prosaic-std';
  font-style: normal;
  font-weight: normal;
  src: url('./fonts/prosaicstd.woff2') format('woff2'),
    url('./fonts/prosaicstd.woff') format('woff');
}

.app-container {
  background-color: var(--darker-purple);
  font-family: 'prosaic-std';
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  min-height: 100vh;
  overflow-x: hidden;
}

button,
.btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-family: 'prosaic-std';
  margin: 0;
  padding: 0;
}

button.active,
.btn.active {
  text-decoration: underline;
  text-decoration-color: var(--orange);
  text-decoration-thickness: 2px;
  text-underline-position: under;
}

/*** Header ***/
header {
  background-color: var(--dark);
  height: 75px;
  justify-content: center;
}

button.header-btn {
  display: none;
  height: 100%;
  letter-spacing: 0.76px;
  line-height: 2.22;
  margin-left: 54px;
  white-space: nowrap;
  transition: all 0.2s ease-in-out;
  border-bottom: 5px solid var(--dark);
}

button.header-btn:hover {
  border-bottom: 5px solid var(--orange);
}

button.header-btn.active {
  border-bottom: 5px solid var(--orange);
  text-decoration: none;
}

.header-logo {
  height: 40px;
  object-fit: contain;
}

.header-menu-icon {
  cursor: pointer;
  filter: invert();
  height: 40px;
  left: 1.5rem;
  position: absolute;
  top: 1.1rem;
}

.logout-container {
  display: none;
}

/*** UI Controls ***/
.ui-controls {
  justify-content: space-evenly;
}

.btn {
  color: white;
  font: inherit;
  font-size: 18px;
}

.btn.active {
  text-decoration: underline;
  text-decoration-color: var(--orange);
  text-decoration-thickness: 2px;
  text-underline-position: under;
}

/*** Footer ***/
footer {
  background-color: var(--dark);
  height: 322px;
  margin-top: auto;
}

.footer-gradient {
  background-image: linear-gradient(
    to right,
    var(--orange),
    #7b68e7,
    var(--light-purple)
  );
  height: 1rem;
}

.footer-logo {
  height: 120px;
  margin-top: 3rem;
  object-fit: contain;
}

.social-link {
  background-color: var(--purple);
  height: 40px;
  width: 40px;
}

.footer-icons-container {
  flex-direction: column;
}

.footer-icons {
  margin-top: 5rem;
}

@media (min-width: 992px) {
  header {
    justify-content: flex-start;
    padding-left: 20px;
  }

  .header-menu-icon {
    display: none;
  }

  button.header-btn {
    display: block;
  }

  .logout-container {
    display: flex;
  }

  .footer-logo {
    margin-top: 0;
  }

  .footer-nav-btns {
    display: flex;
  }

  .footer-icons-container {
    flex-direction: row;
  }
}
