.modal.dramcorp {
  background: var(--darker-purple);
}

.modal.dramcorp a {
  color: white;
}

.modal.dramcorp .content-wrapper {
  color: white;
  border: 4px solid var(--bright-purple);
  border-top: 0 solid transparent;
}

.modal.dramcorp .titlebar {
  background-image: linear-gradient(
    to right,
    var(--orange),
    var(--bright-purple) 80%
  );
}

.modal.dramcorp .cta {
  border: 1px solid var(--orange);
  background: var(--orange);
}

.modal.dramcorp .cta:hover,
.modal.dramcorp .cta:active {
  background: var(--dark-orange);
}
